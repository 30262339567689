import type { GqlError } from "nuxt-graphql-client";

function isGqlError(error: unknown): error is GqlError {
  return (error as GqlError).client !== undefined;
}
export type GqlErrorHandlingOptions = {
  toast?: {
    title?: string;
    description?: string;
  };
  StatusMessage404?: string;
  redirect?: boolean;
};

export default function useGqlErrorHandling(
  error: Ref<unknown>,
  data: Ref<unknown>,
  options?: GqlErrorHandlingOptions
) {
  const { $logger } = useNuxtApp();
  const toasts = useToastsStore();
  watch(data, (data) => {
    if (!data) {
      if (options?.redirect) {
        showError(
          createError({
            statusCode: 404,
            statusMessage: options?.StatusMessage404 || "Page Not Found"
          })
        );
      }
    }
  });
  watch(error, (error) => {
    if (!error) return;
    if (isGqlError(error)) {
      $logger.error("Is GqlError", JSON.stringify(error, null, 2));
      if (options?.redirect) {
        showError(
          createError({
            statusCode: 500,
            statusMessage:
              options?.StatusMessage404 ||
              `Error on ${error.operationName}
            ${error.gqlErrors[0].message}`
          })
        );
      } else {
        toasts.add({
          title: options?.toast?.title || `Error on ${error.operationName}`,
          description: options?.toast?.description || error.gqlErrors[0].message,
          type: "error"
        });
      }
    } else {
      $logger.debug("Is no GqlError", JSON.stringify(error, null, 2));
      throw error;
    }
  });
}
